<template>
  <div class="course part">
    <h3 class="tit">视频教程</h3>
    <div class="container">
      <div class="inner" v-for="(item, indexR) in roadmap" :key="indexR">
        <!-- 这里用v-html是因为后端数据php一块有个问题 -->
        <h4
          v-html="'<span>第' + numToCn(indexR) + '阶段</span> ' + item.stageDec"
        ></h4>
        <ul>
          <li
            v-for="(item, index) in item.subjectStageLineEntityList"
            :key="index"
            v-text="item.lineName"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoadMap } from "network/download";

export default {
  name: "RoadMap",
  props: {
    subjectId: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      roadmap: [],
    };
  },
  watch: {
    subjectId() {
      this.getRoadMap();
    },
  },
  methods: {
    // 学习路线
    async getRoadMap() {
      const res = await getRoadMap(this.subjectId);
      this.roadmap = res.data;
    },

    numToCn(i) {
      switch (i + 1) {
        case 1:
          return "一";
          break;
        case 2:
          return "二";
          break;
        case 3:
          return "三";
          break;
        case 4:
          return "四";
          break;
        case 5:
          return "五";
          break;
      }
      return i + 1;
    },
  },
  mounted() {
    this.getRoadMap();
  },
};
</script>

<style scoped>
</style>