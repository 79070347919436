<template>
  <div class="sourceCode part">
    <h3 class="tit">课程源码</h3>
    <div class="inner">
      <ul>
        <li v-for="(item, index) in sourceCodeList" v-text="item.codeName"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getSourceCodeList } from "network/download";

export default {
  name: "SourceCode",
  props: {
    subjectId: {
      type: [Number, String],
      default: 1,
    },
  },
  watch: {
    subjectId() {
      this.getSourceCodeList();
    },
  },
  data() {
    return {
      sourceCodeList: [],
    };
  },
  methods: {
    async getSourceCodeList() {
      const res = await getSourceCodeList(this.subjectId);
      this.sourceCodeList = res.data;
    },
  },
  created() {
    this.getSourceCodeList();
  },
};
</script>

<style scoped>
</style>