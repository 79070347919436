<template>
  <div class="download__wrapper">
    <img
      src="@/assets/images/downloadPage_banner.png"
      alt=""
      style="width: 100%"
    />

    <div class="download__container">
      <aside>
        <h2>各科学习路线</h2>
        <div class="subject">
          <div
            :class="{ active: index === curSubjectIdx }"
            @click="chosedSubject(index, item)"
            :key="item.id"
            v-for="(item, index) in subjectArr"
            class="des"
          >
            <!-- <img :src="item.url" :alt="item.subjectName" /> -->
            <h2 :title="item.subjectName" v-cloak>
              {{ item.subjectName }}
              <i
                class="el-icon-caret-right"
                v-show="index === curSubjectIdx"
              ></i>
            </h2>
          </div>
        </div>
      </aside>

      <main>
        <div class="topBox">
          <h2
            class="title"
            v-text="activeSubjectName + '全套资源打包下载'"
          ></h2>
          <p class="vice-tit">千锋系统视频教程，每周更新，人气爆表！</p>
          <p class="vice-tit">大咖巨制，手把手带你从入门到成神</p>

          <ul class="adv">
            <li>教程成体系递进</li>
            <li>课程深广度兼具</li>
            <li>热门技术持续更新</li>
            <li>千锋强师倾囊录制</li>
          </ul>

          <div class="downloadHint">
            <img :src="qrcodeUrl" class="qrcode" />
            <div class="texts">
              <div class="item">
                <p>扫码进入</p>
                <h3 v-text="officialName"></h3>
              </div>
              <div class="item">
                <!-- <p>选择所需学科</p> -->
                <!-- <h3 v-text="activeSubjectName"></h3> -->
                <h3>选择所需学科</h3>
              </div>
              <div class="item" style="width: 180px">
                <!-- <p>获取本学科教程</p> -->
                <!-- <h3>下载地址及密码</h3> -->
                <h3>领取全套课程资料</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="content">
          <road-map v-if="subjectArr.length" :subjectId="activeSubjectId" />
          <tools :subjectIdx="curSubjectIdx" />
          <source-code :subjectId="activeSubjectId" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// import 'assets/css/download.less'

import { getSubjectList } from "network/common";
import { getSourceCodeList } from "network/download";
import RoadMap from "./childComps/RoadMap";
import Tools from "./childComps/Tools";
import SourceCode from "./childComps/SourceCode";

export default {
  name: "Download",
  components: {
    RoadMap,
    Tools,
    SourceCode,
  },
  data() {
    return {
      curSubjectIdx: 0,
      subjectArr: [],
      qrcodeUrl: "https://kodo.1000phone.com/Fm6knkrQs7ZdQmnGaE_FlYrWCwRM",
      officialName: "千锋学习站",
    };
  },
  computed: {
    activeSubjectName() {
      if (this.subjectArr.length !== 0)
        return this.subjectArr[this.curSubjectIdx].subjectName;
      return "";
    },
    activeSubjectId() {
      if (this.subjectArr.length !== 0)
        return this.subjectArr[this.curSubjectIdx].id;
      return 1;
    },
  },
  methods: {
    async INIT_INFO() {
      await this.getSubjectList();
      this.getQRcode();
    },
    async getSubjectList() {
      const res = await getSubjectList();
      this.subjectArr = res.data;
    },
    async getQRcode() {
      const res = await getSourceCodeList();
      this.qrcodeUrl = res.data[0].downUrl;
      this.officialName = res.data[0].officialName;
    },

    chosedSubject(index, item) {
      this.curSubjectIdx = index;
      this.INIT_INFO();
      document.querySelector(".download__container").scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    },
  },
  created() {
    this.INIT_INFO();
  },
};
</script>

<style scoped>
@import url("~@/assets/css/download.css");
</style>