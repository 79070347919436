import { request } from './request'

export function getSubjectList() {
  return request({
    url: '/api/subject/list/'
  })
}
export function getRoadMap(sid) {
  return request({
    url: '/api/subjectstage/list/'+sid
  })
}
export function getSourceCodeList() {
  return request({
    url: '/api/soundcode/list'
  })
}