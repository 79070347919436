<template>
  <div class="tools part">
    <h3 class="tit">学习工具</h3>
    <div class="inner">
      <ul>
        <li v-for="item in tools[subjectIdx].data" v-cloak>
          <a href="javascript:;" @click="showDialog(item)">{{
            item.toolName
          }}</a>
        </li>
      </ul>
    </div>

    <el-dialog
      :title="'下载：' + curToolName"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <div class="dialog-body">
        <el-button
          type="primary"
          plain
          v-clipboard:copy="curCode"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          >复制提取码：{{ curCode }}
          <i class="el-icon-document-copy el-icon--right"></i>
        </el-button>
        <el-button type="success" plain @click="openPan"
          >立即跳转到网盘
          <i class="el-icon-s-promotion el-icon--right"></i>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  name: "Tools",
  props: {
    subjectIdx: 0,
  },
  data() {
    return {
      dialogVisible: false,
      curCode: "",
      curLink: "",
      curToolName: "",
      tools: [
        {
          subject: "Java",
          data: [
            {
              toolName: "JDK8 Windows 64",
              link: "https://pan.baidu.com/s/1OG6wD-Fvgxu6FwuOUMDmQQ",
              code: "yu0l",
            },
            {
              toolName: "JDK-11 Windows 64",
              link: "https://pan.baidu.com/s/1OG6wD-Fvgxu6FwuOUMDmQQ ",
              code: "yu0l",
            },
            {
              toolName: "MySQL（Windows+Mac）",
              link: "https://pan.baidu.com/s/1VQ_tGb5SQCF587hYHw4s8g",
              code: "jhmt",
            },
            {
              toolName: "EditPlus",
              link: "https://pan.baidu.com/s/1IXry8XIjxgevfw-gzJRbAw",
              code: "r55w",
            },
            {
              toolName: "Maven",
              link: "https://pan.baidu.com/s/1MHbkszQG1RQTLWIMU1rYtw",
              code: "zpsb",
            },
            {
              toolName: "版本控制",
              link: "https://pan.baidu.com/s/1bHQ-Qus4BjRijOdGOZq2Rg",
              code: "dd6i",
            },
            {
              toolName: "IDEA",
              link: "https://pan.baidu.com/s/1QU9j0S62OarCWcNWMG0ufQ",
              code: "ouh9",
            },
          ],
        },
        {
          subject: "Web前端",
          data: [
            {
              toolName: "Node",
              link: "https://pan.baidu.com/s/13rKMYOQSGLSGjefV9YggYA",
              code: "1122",
            },
            {
              toolName: "webStorm",
              link: "https://pan.baidu.com/s/1i_Vu2ul3KZLnPdy1GCRiKg ",
              code: "1122",
            },
            {
              toolName: "Typora",
              link: "https://pan.baidu.com/s/1zywbn9EIP6kbsoz-n0_-eg",
              code: "1122",
            },
            {
              toolName: "VS code",
              link: "https://pan.baidu.com/s/1UeOsQPzP_GLTzz49wb9rUw",
              code: "2dff",
            },
            {
              toolName: "Snipaste",
              link: "https://pan.baidu.com/s/13E_OJEV3ovvgEOJEwu8L7w",
              code: "8ffc",
            },
            {
              toolName: "HBuilderX",
              link: "https://pan.baidu.com/s/1os6dk7nuVabbFZmEuTUhhA",
              code: "cc47",
            },
            {
              toolName: "Dreamweaver",
              link: "https://pan.baidu.com/s/1aOsQzzPThg-uhA8vPpm16Q ",
              code: "1122",
            },
          ],
        },
        {
          subject: "大数据",
          data: [
            {
              toolName: "zookeeper",
              link: "https://pan.baidu.com/s/1CrhqpX9TgYPlqx8s1rtOBg",
              code: "fq6l",
            },
            {
              toolName: "Redis",
              link: "https://pan.baidu.com/s/1uSasF7U8_DMaSIN-8AKkKg ",
              code: "ib97",
            },
            {
              toolName: "Kafka",
              link: "https://pan.baidu.com/s/14oWSpoWjgK6fMQBQQPRogg",
              code: "0786",
            },
            {
              toolName: "Flunm",
              link: "https://pan.baidu.com/s/1UDisAPk7kASLI2lNayi5DA",
              code: "t2x0",
            },
            {
              toolName: "Eclipse",
              link: "https://pan.baidu.com/s/1AwZvJygvsCtzssCv1cjxAA",
              code: "0kw3",
            },
          ],
        },
        {
          subject: "Python",
          data: [
            {
              toolName: "Python3.9",
              link: "https://pan.baidu.com/s/1xs_zb2kPhoAMQwMGUvrmGQ",
              code: "u1h5",
            },
            {
              toolName: "PyCharm",
              link: "https://pan.baidu.com/s/1g6_aAoTwqOScWgt8e-DwVA",
              code: "ajdx",
            },
            {
              toolName: "Anaconda",
              link: "https://pan.baidu.com/s/18AN-juvJVp4RaRknOeJ4cQ",
              code: "6tu4",
            },
            {
              toolName: "Visual Studio Code",
              link: "https://pan.baidu.com/s/1UeOsQPzP_GLTzz49wb9rUw",
              code: "2dff",
            },
            {
              toolName: "spss",
              link: "",
              code: "",
            },
            {
              toolName: "Minitab",
              link: "",
              code: "",
            },
            {
              toolName: "抓包工具",
              link: "",
              code: "",
            },
            {
              toolName: "商业智能工具",
              link: "",
              code: "",
            },
          ],
        },
        {
          subject: "UI设计",
          data: [
            {
              toolName: "PS CC2021",
              link: "https://pan.baidu.com/s/1epfyOfK3Ig9u96j9xg8tsQ",
              code: "b07n",
            },
            {
              toolName: "Sketch",
              link: "https://pan.baidu.com/s/19ZRbHXoB3cG3raCBsuvkJw",
              code: "e85t",
            },
            {
              toolName: "C4D",
              link: "https://pan.baidu.com/s/1xwFOJK1DuuvMDWIrWAjoJg",
              code: "j7z9",
            },
            {
              toolName: "Axrue PR",
              link: "https://pan.baidu.com/s/11xsV-B9hbG9KcziP4qEUeQ ",
              code: "nw6f",
            },
          ],
        },
        {
          subject: "物联网",
          data: [
            {
              toolName: "visual studio 2019",
              link: "https://pan.baidu.com/s/10VMZFmunCrEbdBWX_lM2Xw",
              code: "lyac",
            },
          ],
        },
        {
          subject: "Linux云计算",
          data: [
            {
              toolName: "CentOS-7",
              link: "https://pan.baidu.com/s/1lOrRQM_N64ExiH09rJFPlw",
              code: "4qgh",
            },
            {
              toolName: "VMware12.Keymaker",
              link: "https://pan.baidu.com/s/101WwjZkyNGuzJ6YFhaT2Lw",
              code: "k9kr",
            },
            {
              toolName: "VMware Workstation 12",
              link: "https://pan.baidu.com/s/1Ogm94o1sfOGmsBp4nN74gg",
              code: "7jwt",
            },
            {
              toolName: "finalshell",
              link: "https://pan.baidu.com/s/1JNjNwge9wx3Xy_uGrSkCxA",
              code: "udsv",
            },
          ],
        },
        {
          subject: "软件测试",
          data: [
            {
              toolName: "Oracle",
              link: "https://pan.baidu.com/s/1yQVDKBUOH-i2fgYezrhgJw",
              code: "grvo",
            },
            {
              toolName: "Jmeter",
              link: "https://pan.baidu.com/s/1SrkfukDxhyaIpbnODe4Yfg",
              code: "xlz7",
            },
            {
              toolName: "VMware  15",
              link: "https://pan.baidu.com/s/1e_283jxs-qAmJW2NCWDHRw ",
              code: "p1g7",
            },
            {
              toolName: "VMware  10",
              link: "https://pan.baidu.com/s/1S0nJiyG8vwmgZxBRzNuimQ",
              code: "13k8",
            },
            {
              toolName: "Navicat",
              link: "https://pan.baidu.com/s/1FwXV4kAdFDjptYIJFHFejw",
              code: "jw3w",
            },
            {
              toolName: "Eclipse",
              link: "https://pan.baidu.com/s/1AwZvJygvsCtzssCv1cjxAA",
              code: "0kw3",
            },
          ],
        },
        {
          subject: "Unity3D",
          data: [],
        },
        {
          subject: "PHP",
          data: [],
        },
      ],
    };
  },
  methods: {
    showDialog(v) {
      this.curToolName = v.toolName;
      this.curCode = v.code;
      this.curLink = v.link;

      this.dialogVisible = true;
    },
    openPan() {
      window.open(this.curLink);
    },
    onCopySuccess() {
      Message({
        message: "提取码已复制到剪切板",
        type: "success",
        offset: 60,
      });
    },
    onCopyError() {
      Message({
        message: "提取码复制失败，请自行输入",
        type: "error",
        offset: 60,
      });
    },
  },
};
</script>

<style scoped>
.dialog-body {
  display: flex;
  justify-content: space-between;
}
</style>